import React from 'react'

const Footer = () => {
  return (
    <div className=' bg-customYellow text-xs text-center   md:text-sm  pl-3 py-1 text-white'>
        detayacademy@gmail.com
      </div>
  )
}

export default Footer