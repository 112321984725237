import React, { useState } from 'react'
import toast from 'react-hot-toast'
import axios from "axios"
import { useNavigate } from 'react-router-dom'
const Contact = () => {
  const [contactData,setContactData] = useState({name:"",email:"",message:""})
  const navigate = useNavigate()
  const handleChange = (e) => setContactData(prevData =>  { return {...prevData, [e.target.name] : e.target.value }})

  const handleSubmit =async (e) => {
    e.preventDefault()
    if(contactData.message.length < 5) return toast.error("Lütfen daha uzun bir mesaj giriniz!")
    if (!contactData.email.includes("@") || contactData.email.split(".").length < 2) return toast.error("Lütfen geçerli bir mail adresi gir!")
      try {
    const response =await axios.post(`${process.env.REACT_APP_BASE_URL}/contacts`,contactData)
        const newContact = await response?.data
        if(!newContact) return toast.error("Lütfen Tekrar Deneyin!")
        setContactData({name:"",email:"",message:""})
        toast.success(`${newContact.name}, mesajın başarıyla iletildi. Sana en kısa sürede mail üzerinden dönüş yapılacaktır.`)
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='bg-customYellowLight' id="contact">
      <div>
        <h2 className='bg-customYellow lg:py-7 py-3 text-center text-white  text-xl lg:text-2xl lg:text-[35px]'>İletişim</h2>
      </div>
      <div className='flex flex-col contact w-[80%] lg:w-[35%] mx-auto py-20'>
        <div className='flex justify-between mb-7'>
            <input required={true} type="text" name="name" value={contactData.name} onChange={handleChange} className='w-[45%]' placeholder='İsmin..' />
            <input required={true} type="email" name="email" value={contactData.email} onChange={handleChange} className='w-[45%]' placeholder='Mail adresin..' />
        </div>
        <textarea required={true} onChange={handleChange} value={contactData.message}  spellCheck={false} name="message" rows={10} className=' resize-none' placeholder='Mesajın...'  />
        <button onClick={handleSubmit} className='py-4 text center bg-customYellow text-center text-xl rounded mt-5 border-2 border-customYellow transition-all duration-200 hover:text-customYellow text-white font-semibold hover:bg-white'>Gönder</button>
      </div>
    </div>
  )
}

export default Contact
