import React from 'react'

const Plans = () => {
  return (
    <div className='mt-20' id='plans'>
      <div>
      <h2 className='bg-customYellow lg:py-7 py-3 text-center text-white  text-xl lg:text-2xl lg:text-[35px]'>Koçluk Paketlerimiz</h2>
      </div>
      <div className='mx-2 space-y-20 lg:space-y-0 lg:mx-20 mt-10 lg:mt-20 flex flex-col lg:flex-row justify-between'>


        {/* Monthly Plan */}
        <div className='w-[100%] lg:w-[30%] shadow-xl shadow-gray-500 rounded-md hover:scale-105 lg:scale-100  duration-300 transition-all bg-customPurpleLighter pb-5 text-white'>
          <img src="/img/monthly-plan.png" className='rounded-t-md mb-5' alt="" />
          <div>
            <div className='text-customYellow text-center flex flex-col items-center'>
              <h3 className=' text-2xl text-white'>Aylık Danışmanlık Paketi</h3>
              <div className=' text-start my-3'>
                <div className=' line-through -mb-2 text-2xl'>1999,00 ₺</div>
                <div className='text-3xl'>1599,00 ₺</div>
              </div>
            </div>
            <ul className='px-[60px] flex flex-col space-y-4 mt-5 list-disc'>
              <li>Alanınızda derece yapmış özel koç ile 7/24 iletişim imkanı</li>
              <li>Haftada bir ana görüşme </li>
              <li>Haftada en az bir defa ara görüşme</li>
              <li>Günlük raporlama sistemi</li>
              <li>Deneme sonucuna göre öğrenciye yönlendirmeler</li>
              <li>Motivasyon takibi  </li>
              <li>Telegram gruplarına sınırsız erişim</li>
            </ul>
          </div>
          <a href="https://t.me/detay_mentorluk_bot?start=start" target='_blank'><div className='bg-customPurpleLight cursor-pointer px-4 py-2 text-2xl mx-auto s w-[90%] text-center mt-20 rounded-md'>Bilgi Al</div></a>
        </div>
        
        {/* Seasonly Plan */}
        <div className='w-[100%] lg:w-[30%] shadow-xl shadow-gray-500 rounded-md  hover:scale-105 duration-300 transition-all bg-customBlueLight  pb-5 text-white'>
          <img src="/img/seasonly-plan.png" className='rounded-t-md mb-5' alt="" />
          <div>
            <div className='text-customYellow text-center flex flex-col items-center'>
              <h3 className=' text-2xl text-white'>3 Aylık Danışmanlık Paketi</h3>
              <div className=' text-start my-3'>
                <div className=' line-through -mb-2 text-2xl'>5999,00 ₺</div>
                <div className='text-3xl'>4499,00 ₺</div>
              </div>
            </div>
            <ul className='px-[60px] flex flex-col space-y-4 mt-5 list-disc'>
              <li>Alanınızda derece yapmış özel koç ile 7/24 iletişim imkanı</li>
              <li>Haftada bir ana görüşme </li>
              <li>Haftada en az bir defa ara görüşme</li>
              <li>Günlük raporlama sistemi</li>
              <li>Deneme sonucuna göre öğrenciye yönlendirmeler</li>
              <li>Motivasyon takibi  </li>
              <li>Telegram gruplarına sınırsız erişim</li>
            </ul>
          </div>
          <a href="https://t.me/detay_mentorluk_bot?start=start" target='_blank'><div className='bg-customBlue cursor-pointer px-4 py-2 text-2xl mx-auto  w-[90%] text-center mt-20 rounded-md'>Bilgi Al</div></a>
        </div>

        
        {/* Yearly Plan */}
        <div className='w-[100%] lg:w-[30%] shadow-xl shadow-gray-500 rounded-md  hover:scale-105 lg:scale-100 duration-300 transition-all bg-customGreenLight pb-5 text-white'>
          <img src="/img/yearly-plaan.png" className='rounded-t-md mb-5' alt="" />
          <div>
            <div className='text-customYellow text-center flex flex-col items-center'>
              <h3 className=' text-2xl text-white'>Yıllık Danışmanlık Paketi</h3>
              <div className=' text-start my-3'>
                <div className=' line-through -mb-2 text-2xl'>16999,00 ₺</div>
                <div className='text-3xl'>12999,00 ₺</div>
              </div>
            </div>
            <ul className='px-[60px] flex flex-col space-y-4 mt-5 list-disc'>
              <li>Alanınızda derece yapmış özel koç ile 7/24 iletişim imkanı</li>
              <li>Haftada bir ana görüşme </li>
              <li>Haftada en az bir defa ara görüşme</li>
              <li>Günlük raporlama sistemi</li>
              <li>Deneme sonucuna göre öğrenciye yönlendirmeler</li>
              <li>Motivasyon takibi  </li>
              <li>Telegram gruplarına sınırsız erişim</li>
            </ul>
          </div>
          <a href="https://t.me/detay_mentorluk_bot?start=start" target='_blank'><div className='bg-customGreen cursor-pointer px-4 py-2 text-2xl mx-auto  w-[90%] text-center mt-20 rounded-md'>Bilgi Al</div></a>
        </div>



      </div>

    </div>
  )
}

export default Plans