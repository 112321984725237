import React from 'react'

const Purpose = () => {
  return (
    <div className='bg-[#f7f7f7]' id="purpose">
      <div>
        <h2 className='bg-customYellow lg:py-7 py-3 text-center text-white  text-xl lg:text-2xl lg:text-[35px]'>Detay'ın Amacı</h2>
        <div className='bg-customGrayDark  text-white text-sm lg:text-xl pl-2 lg:pl-4 py-3'>Bunu bir olay akışı üzerinden düşünelim.</div>
      </div>
      {/* SECTIONS */}
      <div className='mt-20'>
        {/* SECTION-1 */}
        <div className='flex flex-col lg:flex-row items-center   justify-between w-[90%] mx-auto'>
            <img src={"/img/clock-person.jpg"} className='w-[80%] lg:w-[30%] rounded-lg shadow-2xl hover:scale-105 transition-all duration-200' alt="" />
          <div className='relative   px-10 lg:px-20 text-customGray flip-effect'>
            <img src={"/img/quote-mark.png"} className='h-10 flip-effect-inner lg:h-20 mt-10 lg:mt-0' alt="" />
            <h3 className='text-[35px] mb-2 lg:mb-0  lg:text-[50px] ml-5 lg:ml-10'>Sınav geldi çattı.</h3>
            <div className='lg:text-xl mb-10 lg:mb-0 ml-5 lg:ml-10 lg:leading-loose'>
            Hayalinizdeki meslek için ders çalışmaya başlayacağınıza karar verdiniz ya da halihazırda çalışıyorsunuz ama ne yapmanız gerektiğini bilmiyorsunuz.
            </div>
          </div>
            <img src={"/img/exhousted-girl.png"} className='w-[80%] lg:w-[30%] shadow-2xl rounded-lg hover:scale-105 transition-all duration-200' alt="" />
        </div>

        {/* SECTION 2 */}
        <div className='bg-customGrayDark relative flex flex-col lg:flex-row text-customGrayLight mt-20'>
          
          <div className=' lg:w-[50%] w-[100%]    lg:px-[100px] relative px-5 mt-5 lg:mt-[8%] mb-10 lg:mb-0'>
            <div className=' text-[70px] lg:text-[100px] absolute'>"</div>
            <h3 className='text-[35px] mt-10 lg:mt-20 lg:text-[50px] ml-5 '>Bu hafta ne çalışmalıyım ?</h3>
            <div className='text-md lg:text-lg leading-relaxed lg:leading-loose w-[75%] lg:mt-2 mt-6 ml-5'>
            Ders çalışmaya başlayalı bir süre oldu ama kafanız çok karıştı. Düzenli bir programınız ya da neler yapmanız gerektiğini söyleyen kimse yok ve kendinizi “TYT netlerim neden artmıyor ya ?” derken buluyorsunuz.
            </div>
          </div>
          <div className='lg:w-[50%] w-[100%]'>
            <img src="/img/exhousted-person2.png" className='max-w-full' alt="" />
          </div>
        </div>

        {/* SECTION 3 */}
        <div className='relative flex flex-col lg:flex-row justify-end bg-[#a73e3e] mt-10 lg:mt-20'>
          <img src="/img/detay-phone5.png" className=' lg:w-[65%] w-[100%] ' alt="" />
          <div className=' text-customGrayLight w-full lg:w-[50%]     lg:pl-[100px] relative px-5 mt-5 lg:mt-[8%] mb-10 lg:mb-0'>
            <div className=' text-[70px] lg:text-[100px] absolute'>"</div>
            <h3 className='text-[35px] mt-10 lg:mt-20 lg:text-[50px] ml-5 '>Çözümü Detay’da</h3>
            <div className='text-md lg:text-lg leading-relaxed lg:leading-loose w-[75%] lg:mt-2 mt-3 ml-5'>
            Artık bu gibi sorularının cevabını bulabildiğin
sana özel olarak seçilmiş bir koçun var.
Ayrıca tekrardan aklına bir soru gelirse 7/24 Detay App üzerinden sorabilirsin. 
            </div>
          </div>
        </div>

        
        {/* SECTION 4 */}
        <div className='bg-customBlueLighter relative flex flex-col lg:flex-row text-customGray mt-10 lg:mt-20'>
       
          <div className=' lg:w-[50%] w-[100%]    lg:px-[100px] relative px-5 mt-5 lg:mt-[8%] mb-10 lg:mb-0'>
            <div className=' text-[70px] lg:text-[100px] absolute'>"</div>
            <h3 className='text-[35px] mt-10 lg:mt-20 lg:text-[50px] ml-5 '>Sana özel program</h3>
            <div className='text-md lg:text-lg leading-relaxed lg:leading-loose w-[75%] lg:mt-2 mt-3 ml-5'>
            Koçunla yapacağın haftalık sesli ve görüntülü görüşmelerde olarak sana özel hazırladığı program sayesinde ne çalışman gerektiği hakkında bir şüphen kalmayacak. Ayrıca her gün koçunla rapor hakkında iletişime geçerek sürecini yönetebileceksin.
            </div>
          </div>

          <div className='lg:w-[50%] w-[100%]'>
            <img src="/img/detay-pc.png" className='max-w-full' alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Purpose