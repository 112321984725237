import React from 'react'

const Home = () => {
  return (
    <div className='relative' id="home">
      <div>
        <img src={"/img/detay-banner.png"} className='h-[100%] w-[100%] min-h-[200px]' alt="" />
      </div>
        <a href="https://t.me/detay_mentorluk_bot?start=start" className='bg-customPurple border-2 border-customPurple hover:text-customPurple  hover:bg-white transition-all duration-300 lg:p-4 p-2  text-sm lg:text-2xl rounded-lg text-white absolute bottom-[5%] lg:bottom-[12%] left-[7%]'>Hemen Başla</a>
    </div>
  )
}

export default Home