import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import Plans from "./components/Plans";
import Purpose from "./components/Purpose";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="">
      <Toaster
  position="top-right"
  reverseOrder={false}
  gutter={8}
  containerClassName=""
  containerStyle={{}}
  toastOptions={{
    // Define default options
    className: '',
    duration: 3000,
    style: {
      background: '#fff',
      color: '#1E1E1E',
      fontSize: "15px"
    },
    success:{
      duration:45000
    }

  }}
/>
      <Header />
      <Home />
      <Purpose />
      <Plans />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
