import React from 'react'
import { Link } from 'react-router-dom'
const Header = () => {
  return (
    <div className='w-full flex flex-col'>
      <div className=' bg-customYellow text-xs text-center md:text-left  md:text-sm  pl-3 py-1 text-white'>
        <a href="mailto:detaymentorluk@gmail.com">detaymentorluk@gmail.com</a>
      </div>
      <header className='flex items-center justify-between bg-customBlue2 px-10'>
        <div className=' flex items-center py-3 '>
          <a href="/"><img src={"/img/logo.png"} className='h-10' alt="" /></a>
          <nav className=' items-center space-x-7 ml-10 text-sm md:flex hidden text-white'>
            <a href="#purpose">Amacımız</a>
            <a href="#plans">Koçluk Paketleri</a>
            <a href="#about">Bizi Tanıyın</a>
            <a href="#contact">İletişim</a>
          </nav>
        </div>
        <div>
          <a className='bg-white text-customBlue2 px-3 py-2  rounded md:rounded-md border-customBlue2 md:font-semibold ' href="https://t.me/detay_mentorluk_bot?start=start">Mentörlük Al</a>
        </div>
      </header>
    </div>
  )
}

export default Header