import React from 'react'

const About = () => {
  return (
    <div className='mt-20 bg-customBlueLighter ' id="about">
      <h2 className='bg-customBlue2 text-white  text-xl lg:text-2xl lg:text-[35px] py-3 lg:py-7 text-center'>Bizi Tanıyın</h2>
      <div className='flex  lg:flex-row flex-col-reverse text-customGrayDark mx-5 lg:mx-20 mt-5 lg:mt-20 pb-20'>
        <div className='lg:w-[60%] flex flex-col justify-center '>
          <div className='flex flex-col space-y-8 lg:text-xl lg:mr-10 leading-relaxed '>
            <span className='font-semibold text-[30px] text-customBlueDark -mb-6 lg:-mb-0'>Kurucumuz:</span>
            <span>Merhaba, ben Muhammet Ali Baykuş. YKS 2024 sınavında TYT'de 401. ve SAY'da 1122. olarak elde ettiğim başarı, eğitim yolculuğumda edindiğim deneyim ve bilgi birikimimin bir sonucudur.</span>
            <span>Detay Akademi, YouTube üzerindeki içeriklerim ve bilgi paylaşımımın talepler doğrultusunda koçluk sistemine dönüştürülmesiyle doğdu. Günümüzde, dinamik bir ekiple sürekli olarak büyüyen ailemizin amacı, öğrencilere en iyi eğitim desteğini sunmaktır.</span>
            <span>Detay Akademi olarak, piyasa koçluk sistemlerinden farklı bir yaklaşım benimseyerek, öğrencilerimizi her yönüyle detaylı bir şekilde analiz ediyor ve sürekli güncellenen programlarla destekliyoruz. Amacımız, öğrencilere kapsamlı ve kişiselleştirilmiş içerik sunarak, hedefledikleri üniversitelere ulaşmalarında etkin bir rehberlik yapmaktır.</span>
            <span>Başarı yolunda yanınızda olmak ve hedeflerinize ulaşmanızda size en iyi desteği sunmak için buradayız.</span>
           </div>
           <a href="https://t.me/detay_mentorluk_bot?start=start" target='_blank' className='mt-20 px-4 py-5 bg-customBlueDark text-white lg:w-[30%] border-2 border-customBlueDark hover:bg-white hover:text-customBlueDark transition-all duration-400 text-center rounded-md text-xl font-semibold'>Bize Ulaş</a>
        </div>
        <div className='w-[100%] lg:w-[40%] relative rounded-md hover:scale-105 transition-all duration-200 mb-10 lg:mb-0'>
        <div className='absolute opensans bottom-[10px] flex flex-col text-white left-5 lg:left-20'>
          <span className='text-xl lg:text-2xl opensans'>Muhammet Ali Baykuş</span>
          <span className='opensans text-sm lg:text-base text-customGrayLight'>Orta Doğu Teknik Üniversitesi, Elektrik Elektronik Mühendisliği</span>
        </div>
        <img src="/img/mali-baykus.jpg" className='w-[100%] rounded-md' alt="" />
        </div>
      </div>
    </div>
  )
}

export default About